$baseLightColor2: var(--base-light-color-2);

.login-page {
    padding-top: 23vh;
    min-height: 600px;

    display: flex;
    align-items: flex-start;
    justify-content: center;

    .login-container {
        height: 80%;
        max-width: 500px;

        padding: 3rem 0;

        h1 {
            font-weight: 700;
            font-size: 2rem;
        }

        background-color: $baseLightColor2;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;

        .login-item {
            

            &.login-btn {
                width: 100%;
                font-size: 16px;
                // padding: 0.8rem 1.6rem;

                font-weight: 500;
                text-transform: capitalize;
            }
        }
    }
}