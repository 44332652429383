$baseDarkColor: var(--base-dark-color);
$baseLightColor2: var(--base-light-color-2);

.category-options {
    min-height: 100vh;
    width: 100%;
    padding: 3.2rem 4rem;
    
    .category-options-container {
        // background-color: red;
        // padding: 3.2rem 1rem 3.2rem 4rem;
    
        .category-title {
            font-weight: 600;
            font-size: 2.1rem;
    
            margin-bottom: 1.7rem;

            .btn-close-sidebar {
                display: none;
            }
        }
    
        .category-option-container {
            width: 80%;
    
            .filter-title {
                font-family: 'Inter';
    
                font-weight: 600;
                text-transform: uppercase;
                font-size: 16px;
    
                padding: 1.3rem 0;
    
                border-bottom: 3px solid $baseDarkColor;
            }
        }
    }

    @media (max-width: 900px) {
        padding: 3.2rem 2rem;

        .category-options-container {
            .category-title {
                font-size: 1.8rem;
            }
    
            .category-option-container {
                width: 100%;
            }
        }
    }

    @media (max-width: 768px) {
        display: none;
        position: fixed;
        top: 80px;
        bottom: 0;
        overflow-y: scroll;

        &.show {
            display: block;
            width: 100%;
            z-index: 999;
            background-color: $baseLightColor2;
    
            padding: 3rem 2rem;
    
            // filter: drop-shadow(0px 8px 20px rgba(173, 181, 189, 0.4));

            .category-options-container {
                .category-title {                
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .btn-close-sidebar {
                        display: block;
                        background-color: rgba(0, 0, 0, 0);
                        border: none;
                        outline: none;
                    }
                }
            }
        }
    }
}
