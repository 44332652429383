$footerBackgroundColor: var(--base-dark-color);
$footerTextColor: var(--base-light-color-2);

.footer-contact {
    width: 100%;
    background-color: $footerBackgroundColor;
    color: $footerTextColor;
    
    /* Across browsers req */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    
    .footer-contact-content {
        padding: 3rem 1.5rem 3rem;

        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 1rem;

        .content-first-column {
            width: 20%;
            min-width: 175px;
    
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            gap: 2rem;

            .content-logo {
                width: 60%;

                img {
                    width: 100%;
                }
            }

            .content-tagline {
                font-family: 'Laro';
                font-size: 1.2rem;
                font-weight: 600;

                text-transform: uppercase;
            }
        }

        .content-second-column {
            width: 45%;
            min-width: 375px;

            display: flex;
            align-items: flex-start;
            justify-content: space-around;

            .footer-menu {
                width: 33%;

                .footer-menu-title {
                    margin-bottom: 1.6rem;
                    font-size: 1.5rem;
                }

                .footer-menu-list {
                    .footer-menu-link {
                        display: block;
                        text-decoration: none;
                        color: inherit;
                        margin-bottom: 0.8rem;
                        font-size: 13px;

                        &:hover {
                            transition: all 0.25s ease;
                            opacity: 0.8;
                        }
                    }
                }
            }

            .content-profile {

            }

            .content-more-info {

            }

            .content-address {

            }

            // @media (max-width: 768px) {
            //     width: 100%;
            //     align-items: space-around;
            // }
        }

        .content-third-column {
            width: 20%;
            min-width: 175px;

            .content-contacts-title {
                margin-bottom: 2rem;
                font-size: 1.5rem;
            }

            .content-contact-items {
                
                
                .contact-item {
                    text-decoration: none;
                    color: inherit;
                    margin-bottom: 0.8rem;
                    
                    cursor: pointer;

                    font-size: 13px;

                    display: flex;
                    align-items: center;
                    gap: 0.3rem;

                    .contact-item-icon {
                        font-size: 17px;
                    }

                    .contact-item-custom-icon {
                        width: 17px;
                    }

                    &:hover {
                        transition: all 0.25s ease;
                        opacity: 0.8;
                    }
                }
            }
        }

        
        @media (max-width: 1200px) {
            .content-first-column {
                width: 30%;
            }

            .content-second-column {
                width: 65%;
            }

            .content-third-column {
                width: 100%;

                .content-contact-items {
                    display: grid;
                    grid-template-columns: 45% 45%;
                    column-gap: 0.8rem;
                
                    // .contact-item {
                    //     width: 50%;
                    // }
                }
            }
        }

        @media (max-width: 990px) {
            gap: 3rem;
            flex-direction: column;

            .content-first-column {
                width: 100%;
                align-items: center;
                margin-bottom: 1rem;

                .content-logo {
                    width: 40%;
                }

                .content-tagline {
                    text-align: center;
                }
            }

            .content-second-column {
                width: 100%;

                .footer-menu {
                    .footer-menu-title {
                        margin-bottom: 1rem;
                    }
    
                    .footer-menu-list {
                        .footer-menu-link {
                            margin-bottom: 0.7rem;
                        }
                    }
                }
            }

            .content-third-column {
                width: 100%;
            }
        }

        @media (max-width: 674px) {
            .content-first-column {
                min-width: 0;
            }

            .content-second-column {
                flex-wrap: wrap;
                min-width: 0;

                .content-profile {
                    width: 50%;
                }
    
                .content-more-info {
                    width: 50%;
                }
    
                .content-address {
                    width: 100%;
                }
            }

            .content-third-column {
                // width: 100%;
            }
        }

        @media (max-width: 600px) {
            .content-first-column {
                .content-logo {
                    width: 60%;
                }
            }
        }

        @media (max-width: 500px) {
            padding: 4rem 1.2rem 4rem;

            .content-first-column {
                .content-logo {
                    width: 80%;
                }

                .content-tagline {
                    font-size: 1.3rem;
                }
            }

            .content-second-column {

                .footer-menu {
                    .footer-menu-title {
                        font-size: 1.3rem;
                    }
    
                    .footer-menu-list {
                        .footer-menu-link {
                            font-size: 14px;
                        }
                    }
                }
            }

            .content-third-column {
                .content-contacts-title {
                    font-size: 1.3rem;
                }

                .content-contact-items {

                    .contact-item {
                        font-size: 14px;
                    }
                }
            }
        }

        @media (max-width: 375px) {
            .content-third-column {
                .content-contact-items {
                    grid-template-columns: 1fr;

                }
            }
        }
    }
    
}