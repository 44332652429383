$baseDarkColor: var(--base-dark-color);

$baseGrayColor: var(--base-gray-color);
$baseGrayColor2: var(--base-gray-color-2);

$baseLightColor2: var(--base-light-color-2);

$baseWhite: #FFFFFF;

$baseDangerColor: var(--base-danger-color);

.order-detail {
    // display: grid;
    // grid-template-areas: 'header header'
    //                      'product product'
    //                      'info summary';
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    row-gap: 3rem;
    column-gap: 1rem;

    padding: 1rem 0;

    .order-detail-header {
        grid-area: header;
        width: 60%;

        .header-title {
            border-bottom: 3px solid $baseDarkColor;
            padding: 1rem 0;
            margin-bottom: 1rem;
        }

        .header-info {
            .header-info-item {
                margin: 0.6rem 0;
                
                .header-info-label {
                    margin-right: 4px;
                    color: $baseGrayColor;
                }

                .header-info-value {
                    font-weight: 600;
                }
                
                &#transaction-status {
                    .header-info-value {
                        color: $baseDangerColor;
                        
                        &.done {
                            color: $baseDarkColor;
                        }
                    }
                }
            }
        }
    }

    .order-detail-products {
        grid-area: product;
        width: 60%;

        background-color: $baseWhite;
        border-radius: 6px;
        // border: 1px solid $baseDarkColor;

        padding: 0.5rem;

        .order-table {
            // border-collapse: separate;

            thead {
                tr {
                    th {
                        // border-color: $baseGrayColor2;
                        border: none;
                        font-weight: 500;
                        text-align: center;

                        &.product-table-header {
                            font-family: 'Helvetica';
                            // font-size: 27px;
                            font-size: 1.6rem;
                            text-align: left;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        border-top-width: 1px;
                        border-bottom-width: 0;
                        border-color: $baseGrayColor2;

                        text-align: center;
                        vertical-align: middle;

                        &.product-table-photo {
                            width: 10%;
                            min-width: 100px;

                            text-align: left;

                            img {
                                width: 100%;
                            }
                        }

                        &.product-table-name {
                            text-align: left;
                        }

                        &.product-table-size {
                            width: 15%;
                        }

                        &.product-table-qty {
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    .order-detail-footer {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;

        row-gap: 1.5rem;
        
        width: 60%;

        .order-detail-payment-info {
            min-width: 300px;
            width: 45%;

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 2rem;
    
            .payment-info-item {
                background-color: $baseWhite;
                width: 100%;
                padding: 1rem;
    
                .payment-info-item-header {
                    border-bottom: 1px solid $baseGrayColor2;
                }
    
                .payment-info-item-content {
                    padding: 1.5rem 0;
    
                    .content-title {
                        font-weight: 500;
                    }
    
                    .content-text {
                        margin-top: 20px;
                        text-transform: capitalize;
                    }
                }
            }
        }
    
        .order-detail-summary-container {
            min-width: 300px;
            width: 45%;
    
            background-color: $baseLightColor2;
            // color: white;
            border: 2px solid black;
    
            padding: 1.5rem 1rem;
    
            filter: drop-shadow(0px 8px 20px rgba(173, 181, 189, 0.4));
            border-radius: 6px 6px 0px 0px;
    
            .order-detail-summary-title {
                font-weight: 600;
            }
    
            .order-detail-summary {
                width: 100%;
                height: 100%;
    
                margin: 2rem 0;
    
                display: flex;
                flex-direction: column;
                align-items: stretch;
                justify-content: space-between;
    
    
                .order-detail-summary-table {
                    width: 100%;
    
                    tbody {
                        tr {
                            td {
                                padding: 0.5rem 0;
                            }
                        }
                    }
    
                    tfoot {
                        tr {
                            
                            font-weight: 600;
                            
                            td {
                                padding: 1rem 0;
                            }
    
                            &.total-row {
                                border-top: 1px solid $baseGrayColor;
                                // border-bottom: 1px solid $baseGrayColor;
                                border-bottom: 0;
                            }
                        }
                    }
                }
            }
    
        }

        @media (max-width: 1017px) {
            .order-detail-payment-info,
            .order-detail-summary-container {
                width: 100%;
            }
        }
    }

    .payment-button {
        width: 100%;
    }

    @media (max-width: 880px) {
        .order-detail-header,
        .order-detail-products,
        .order-detail-footer {
            width: 80%;
        }
    }

}