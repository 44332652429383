$baseDarkColor: var(--base-dark-color);
$baseGrayColor: var(--base-gray-color);

$baseLightColor: var(--base-light-color);
$baseLightColor2: var(--base-light-color-2);

$baseDangerColor: var(--base-danger-color);

.shopping-cart-section {
    min-width: 600px;
    width: 65%;

    padding-bottom: 50px;
    margin-bottom: 20px;

    .shopping-cart-title {
        font-weight: 600;
        margin-bottom: 15px;
        font-family: 'Inter';
        font-size: 1.5rem;
    }

    .shopping-cart-table {
        width: 100%;

        thead {
            border-bottom: 1px solid $baseGrayColor;
            
            tr {
                th { 
                    font-family: 'Helvetica';
                    font-weight: 400;
                    font-size: 1rem;
                }
            }
        }

        tbody {
            // display: block;
            overflow: auto;
            max-height: 500px;
            font-size: 0.9rem;
        }

        tr {
            th {
                padding: 8px 0;
            }

            td {
                padding: 8px 0;

                &.product-photo {
                    width: 12%;
                    padding-right: 18px;

                    img {
                        width: 100%;
                    }
                }

                &.product-name {
                    width: 32%;
                    padding-right: 8px;
                }

                &.product-size {
                    width: 15%;
                    & > * {
                        align-self: center;
                    }

                    & > .product-size-select {
                        height: 40px;
                        width: 80%;
                    }
                }

                &.product-qty {
                    width: 15%;
                    margin: 0 auto;
                    
                    & > .product-qty-counter {
                        height: 32px;

                        .quantity-input {
                            font-size: 12px;
                        }
                    }
                }

                &.product-price {
                    width: 20%;
                    margin: 0 auto;

                    .price {
                        font-weight: 600;

                        &.discounted {
                            font-size: 12px;
                            text-decoration: line-through $baseDangerColor;
                            -webkit-text-decoration-line: line-through;
                            -webkit-text-decoration-color: $baseDangerColor;
                            display: block;
                        }
                    }
                }   
            }
        }

    }

    @media screen and (max-width: 1275px) {
        min-width: 0;
        // width: 100%;
    }
    
    @media screen and (max-width: 1035px) {
        width: 100%;
    }

    @media screen and (max-width: 768px) {
        overflow-y: visible;
        overflow-x: auto;
        min-height: 200px;

        .shopping-cart-table {
            min-width: 576px;

            thead {
                tr {
                    th { 
                        font-size: 1.2rem;
                    }
                }
            }
    
            tbody {
                // display: block;
                overflow: auto;
                max-height: 500px;
                font-size: 1rem;
            }
    
            tr {
                td {
                    padding: 7px 0;
                    font-size: 14px;
    
                    &.product-photo {
                        width: 12%;
                        padding-right: 15px;
    
                        img {
                            width: 100%;
                        }
                    }
    
                    &.product-name {
                        width: 30%;
                    }
    
                    &.product-size {
                        width: 15%;
                    }
    
                    &.product-qty {
                        width: 20%;

                        .product-qty-counter {
                            &.sm {
                                width: 80px;
                                height: 25px;
                        
                                .btn-toggle {
                                    font-size: 14px;
                                }

                                .quantity-input {
                                    width: 30px;
                                    font-size: 14px;
                                }
                            }
                        }
                    }
    
                    &.product-price {
                        width: 17%;
                    }   
                }
            }
        }
    }
}

.shopping-cart-section-empty {
    min-height: 600px;
}