$baseSuccessColor: var(--base-success-color);
$baseDangerColor: var(--base-danger-color);

$baseLightColor: var(--base-light-color);
$baseLightColor2: var(--base-light-color-2);
$baseGrayColor: var(--base-gray-color);

.general-toast {
    position: fixed;
    z-index: 1051;
    top: 30px;
    right: 30px;

    border: 0;
    border-radius: 5px;

    width: 400px;

    padding: 1.5rem 1rem;

    background-color: $baseLightColor2;

    .general-toast-header {
        background-color: $baseLightColor2;
        border: 0;
        
        .general-toast-title {
            font-family: 'Inter';
            font-weight: 400;

            color: initial;

            margin-right: auto;
        }

        .icon-success {
            margin-right: 6px;
            color: $baseSuccessColor;
        }

        .icon-failed {
            margin-right: 6px;
            color: $baseDangerColor;
        }
    }

    .general-toast-body {
        padding: 1rem;
        background: $baseLightColor2;
        border-radius: 5px;
    }

    @media (max-width: 470px) {
        left: 10px;
        width: 95%;
    }
}