.categories-page {
    display: grid;
    grid-template-columns: 400px auto;
    padding-top: 180px;

    .sidebar-toggler {
        display: none;
    }

    .catalog-container {
        width: 100%;
    }

    @media (max-width: 900px) {
        grid-template-columns: 300px auto;
    }

    @media (max-width: 768px) {
        display: block;
        padding-top: 120px;

        .sidebar-toggler {
            display: block;
            margin-left: 3rem;
            
            border: none;
            outline: none;
            background-color: rgba(0, 0, 0, 0);

            cursor: pointer;
        }

        .catalog-container {
            padding: 20px 2rem;
            // margin: 20px 2rem;
        }
    }
}