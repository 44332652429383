$textColor: var(--base-dark-color);
$backgroundColor: var(--base-primary-color);

$baseDarkColor1: var(--base-dark-color);
$baseDarkColor2: var(--base-dark-color-2);
$baseDarkColor3: var(--base-dark-color-3);

$baseLightColor: var(--base-light-color);
$baseLightColor2: var(--base-light-color-2);

.navbar {
    font-family: 'Helvetica';
    padding: 8px 0 0;
    background-color: $backgroundColor;
    box-shadow: 2px 2px 5px -5px $textColor;
    position: fixed;
    width: 100%;
    z-index: 1001;

    filter: drop-shadow(0px 8px 20px rgba(173, 181, 189, 0.4));

    flex-direction: column;
    
    .navbar-first-row {
        width: 100%;

        padding: 0.1rem 4vw 0.6rem;

        display: flex;
        justify-content: space-between;
        align-items: center;

        .search-bar {
            width: 250px;
            position: relative;
            
            .search-icon {
                position: absolute;
                z-index: 999;
                left: 17px;
                top: 7px;
                width: 12px;
            }
            
            .search-box {
                width: 100%;
                background-color: #E9ECEF;
                padding: 6px 12px 6px 38px;
                border: none;
                outline: none;
                font-size: 12px;
                border-radius: 41px;
            }
        }
    
        .nav-logo {
            width: 120px;
        }

        .mobile-sidebar-toggle {
            font-size: 20px;

            background-color: rgba(0, 0, 0, 0);
            color: $textColor;
            border: none;
            outline: none;

            position: relative;
        }

        @media (max-width: 900px) {
            .search-bar {
                width: 200px;
            }
        }

        @media (max-width: 768px) {
            .search-bar {
                width: 150px;
            }

            .nav-logo {
                width: 120px;
            }
        }
    }

    .navbar-second-row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2rem;

        position: relative;

        .brand-name {
            font-weight: 500;
            font-size: 14px;
            color: $textColor;
            text-decoration: none;
            cursor: pointer;
            border-bottom: 3px solid rgba(0, 0, 0, 0);
            margin: 0;
            padding: 8px 0;

            &:active, 
            &:hover, 
            &:focus,
            &.active {
                font-weight: 600;
                font-size: 15.5px;
                border-bottom: 3px solid $textColor;
            }
        }

        .navbar-hover {
            width: 100%;
            min-height: 200px;
    
            z-index: 1010;
            position: absolute;
            top: 40px;
            left: 0;
            padding: 3rem 2rem;
            display: none;

            &.show {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5%;
            }

            background: rgba(#F8F9FA, 0.8);

            .group-name {
                width: 30%;
                text-align: center;

                font-size: 30px;
                font-weight: 500;
            }

            .group-items {
                width: 65%;

                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
                column-gap: 50px;
                row-gap: 20px;
                
                .group-item {
                    text-decoration: none;
                    color: $baseDarkColor1;
                    font-size: 15px;
                    width: fit-content;

                    &:active, 
                    &:hover, 
                    &:focus,
                    &.active {
                        font-weight: 600;
                    }
                }
            }

        }
    }

    .navbar-third-row {
        width: 100%;
        background-color: $baseDarkColor1;
        color: $baseLightColor2;
        font-size: 13px;

        padding: 0.3rem 0;

        @media (max-width: 420px) {
            font-size: 0.8rem;
        }
    }
}