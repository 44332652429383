$baseGrayColor: var(--base-gray-color);
$baseGrayColor2: var(--base-gray-color-2);

$baseLightColor: var(--base-light-color);
$baseLightColor2: var(--base-light-color-2);

$baseDarkColor: var(--base-dark-color);
$baseDarkColor2: var(--base-dark-color-2);
$baseDarkColor3: var(--base-dark-color-3);

.btn-custom-secondary {
    font-family: 'Inter';

    box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.31);

    border: 0;
    outline: 0;
    // width: 48%;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 0.2s ease;
    
    &.sm {
        gap: 8px;

        border-radius: 50px;
    
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
    
        padding: 0.5rem 1.6rem;
    }

    &.md {
        gap: 8px;
        
        border-radius: 50px;
        
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;

        padding: 1.2rem 1rem;
    }
    
    background-color: $baseLightColor2;
    color: $baseDarkColor;
    border: 3px solid $baseDarkColor;

    &:hover {
        color: $baseDarkColor3;
        border-color: $baseDarkColor3;
    }

    &.disabled,
    &:disabled {
        opacity: 0.3;
    }
}