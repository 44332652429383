.product-catalog-title {
    font-weight: 700;
    text-transform: uppercase;
    margin: 1rem 1rem;
    font-size: 2rem;
}

.product-catalog {
    width: 100%;

    display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));

    margin-bottom: 20px;

    @media screen and (max-width: 360px) {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }
}

.product-catalog-loading-container {
    .product-category-label {

    }

    .product-category-logos {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        row-gap: 25px;
        
        .product-category-logo {
            width: 10%;
            min-width: 150px;

            cursor: pointer;
    
            img {
                width: 100%;
            }
        }
    }
}