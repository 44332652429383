$baseGrayColor: var(--base-gray-color);
$baseGrayColor2: var(--base-gray-color-2);

$baseLightColor: var(--base-light-color);
$baseLightColor2: var(--base-light-color-2);

$baseDarkColor: var(--base-dark-color);
$baseDarkColor2: var(--base-dark-color-2);
$baseDarkColor3: var(--base-dark-color-3);

$baseDangerColor: var(--base-danger-color);

.product-content {
    width: 50%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .product-content-header {
        .product-name {
            font-family: 'Helvetica';
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 2px;
        }

        .product-category-name {
            font-size: 14px;
            display: block;
            color: $baseGrayColor;
            margin-bottom: 10px;
        }

        .product-price {
            font-size: 16px;
            display: block;

            &.discounted {
                font-size: 13px;
                text-decoration: line-through $baseDangerColor;
                -webkit-text-decoration-line: line-through;
                -webkit-text-decoration-color: $baseDangerColor;
            }
        }
    }

    .product-size-list-container {
        height: 40%;

        .product-size-list-title {
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 15px;
        }

        .product-size-list {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            gap: 10px;
            height: 120px;
            max-width: 400px;
            overflow-y: auto;

            .product-size-item {
                border: 2px solid $baseGrayColor;
                border-radius: 5px;
                width: 45px;
                height: 45px;
                cursor: pointer;
                font-size: 16px;

                display: flex;
                align-items: center;
                justify-content: center;

                &.active {
                    border-color: $baseDarkColor;
                }

                &.disabled {
                    opacity: 0.5;
                }
            }
        }

    }
    
    .product-buy-quantity {
        margin-top: 10px;
    }

    .product-btn-container {
        margin-top: 2rem;
        margin-bottom: 30px;

        .btn-add-to-cart {
            font-size: 15px;
            padding: 0.6rem 2rem;

            margin: 10px 0;
        }

        .btn-socmed-container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            
            font-size: 13px;

            gap: 10px;

            .share-icon {
                font-size: 13px;
                color: initial;
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }

    @media (max-width: 990px) {
        width: 100%;
        padding: 0 2rem;
    }
}

.add-to-cart-toast {
    display: grid;

    grid-template-areas: 'image content content'
                         'button button button';
    gap: 20px;

    .cart-item-image {
        grid-area: image;

        img {
            width: 100%;
        }
    }

    .cart-item-content {
        grid-area: content;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;

        .title {
            font-weight: 400;
        }

        .size {
            font-weight: 500;
        }

        .price {
            font-weight: 500;
        }
    }

    .cart-item-button-container {
        grid-area: button;

        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    @media (max-width: 470px) {
        .cart-item-image {
            img {
                width: 80%;
            }
        }

        .cart-item-button-container {
            flex-direction: column-reverse;
            gap: 0.7rem;
            align-items: flex-start;

            .btn-close-toast,
            .btn-check-cart {
                font-size: 12px;
                width: 100%;
            }
        }
    }
}