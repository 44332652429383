.order-page {
    padding-top: 225px;
    padding-bottom: 150px;

    display: flex;
    align-items: flex-start;
    justify-content: center;

    width: 100%;

    .order-search-page {
        width: 80%;

        padding: 1rem 0 2rem;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 0.5rem;

        .order-search-title {
            font-family: 'Inter';
            text-transform: uppercase;
            font-weight: 500;
            margin-bottom: 30px;
        }

        .order-search-container {
            width: 100%;

            .form-control {
                padding: 0.5rem 1rem;
                font-size: 15px;
            }
        }

        .order-search-btn-container {
            width: 100%;

            .order-search-btn {
                width: 80%;
                margin: 0 auto;
                font-size: 15px;
            }
        }
    }
}