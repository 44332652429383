$baseGrayColor: var(--base-gray-color);
$baseGrayColor2: var(--base-gray-color-2);

$baseLightColor: var(--base-light-color);
$baseLightColor2: var(--base-light-color-2);

$baseDarkColor: var(--base-dark-color);
$baseDarkColor2: var(--base-dark-color-2);
$baseDarkColor3: var(--base-dark-color-3);

.photo-carousel-container {
    width: 45%;

    .react-multiple-carousel__arrow {
        background-color: $baseLightColor;
        box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.31);

        &::before {
            color: $baseDarkColor3;
            font-weight: 600;
        }

        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    .photo-carousel-holder {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 60%;
            border-radius: 10px;
        }
    }

    @media (max-width: 1200px) {
        // width: 100%;

        .photo-carousel-holder {
            img {
                width: 80%;
            }
        }
    }

    @media (max-width: 900px) {
        .photo-carousel-holder {
            img {
                width: 85%;
            }
        }
    }
    
    @media (max-width: 900px) {
        width: 80%;
    }
}